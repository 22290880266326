import { useEffect, useLayoutEffect } from 'react';
import './Assets/css/App.css';
import 'react-toastify/dist/ReactToastify.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import theme from './Theme/theme';
import { CommonStateType, LocalStorage, ROUTE } from './Enum';
import {
  getCurrentUser,
  getPublicKey,
} from './Redux/Slice/authenticationSlice';
import { loadCdnUrls, printLogs } from './Utils/service';
import { commonState } from './Redux/Slice/commonSlice';
import LandingPage from './LandingPage/landingPage';
import AuthenticationPanal from './Component/Authentication/authenticationPanal';
import PageNotFound from './Component/pageNotFound';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    loadCdnUrls();
  }, []);

  useEffect(() => {
    // @ts-expect-error Will fix this
    dispatch(getCurrentUser());
    // @ts-expect-error Will fix this
    dispatch(getPublicKey());
  }, []);

  useEffect(() => {
    window.addEventListener('storage', (e) => {
      printLogs('key', 'changed');
      if (e.key === LocalStorage.ACCESS_TOKEN) {
        window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // User is inactive on the current tab
        dispatch(
          commonState({
            type: CommonStateType.TOAST_MESSAGE,
            data: {
              isOpen: false,
              message: '',
              type: '',
              description: '',
            },
          }),
        );
      }
    };

    // Add event listener for visibilitychange
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Wrapper>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/" element={<AuthenticationPanal component="/" />} />
              <Route
                path={`/${ROUTE.LOGIN}`}
                element={<AuthenticationPanal component={ROUTE.LOGIN} />}
              />
              <Route
                path={`/${ROUTE.ACCOUNT_VERIFICATION}`}
                element={
                  <AuthenticationPanal component={ROUTE.ACCOUNT_VERIFICATION} />
                }
              />
              <Route
                path={`/${ROUTE.RESET_PASSWORD_VERIFICATION}`}
                element={
                  <AuthenticationPanal
                    component={ROUTE.RESET_PASSWORD_VERIFICATION}
                  />
                }
              />
              <Route
                path={`/${ROUTE.FORGOT_PASSWORD}`}
                element={
                  <AuthenticationPanal component={ROUTE.FORGOT_PASSWORD} />
                }
              />
              <Route
                path={`/${ROUTE.FORGOT_USERNAME}`}
                element={
                  <AuthenticationPanal component={ROUTE.FORGOT_USERNAME} />
                }
              />
              <Route
                path={`/${ROUTE.RESET_PASSWORD}`}
                element={
                  <AuthenticationPanal component={ROUTE.RESET_PASSWORD} />
                }
              />
              <Route
                path={`/${ROUTE.INVITE_USER}`}
                element={<AuthenticationPanal component={ROUTE.INVITE_USER} />}
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Wrapper>
        </BrowserRouter>
      </ThemeProvider>
      <ToastContainer />
    </>
  );
};

export default App;
